import React, { useState } from "react";
import ReactPlayer from "react-player";

import campus from "../../video/Virtual Tour.mp4";
import "./Landing.css";
import Slider1 from "../../image/final/s1.jpg";
import Slider2 from "../../image/event_cover.jpg";
import Slider3 from "../../image/final/Planting.jpg";
import Slider4 from "../../image/final/Pedagogy.jpg";
import Slider5 from "../../image/final/slider5.jpg";
import Slider6 from "../../image/final/LifeSkillCurricullum.jpg";
import Slider7 from "../../image/final/library.jpg";
import Slider8 from "../../image/final/slider1.jpg";
import Slider9 from "../../image/final/NEP.jpg";
import Slider10 from "../../image/final/slider2.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

function Landing({ video, cover }) {
  const [isMuted, setIsMuted] = useState(true);

  return video ? (
    <div className="position-relative">
      {/* <ReactPlayer
        url={campus}
        loop={true}
        muted={isMuted}
        playing={true}
        controls={false}
        width="100%"
        className="landing_video"
      />
      <GiSpeaker
        size={50}
        className="sound_icon  cursor"
        style={{ display: !isMuted ? null : "none" }}
        onClick={() => setIsMuted(true)}
      />
      <GiSpeakerOff
        size={50}
        className="sound_icon cursor"
        style={{ display: isMuted ? null : "none" }}
        onClick={() => setIsMuted(false)}
      /> */}
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="sliderAlign" src={Slider1} alt="hi" />
          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Nizamuddin
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider2} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Raipur
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider3} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Bhakatpara
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider4} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider5} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Nizamuddin
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider6} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Raipur
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img className="sliderAlign" src={Slider7} alt="" />
          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Bhakatpara
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider8} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute{" "}
              </p>
            </div> */}
          </center>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <img className="sliderAlign" src={Slider9} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute{" "}
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={Slider10} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute{" "}
              </p>
            </div> */}
          </center>
        </SwiperSlide>
      </Swiper>
    </div>
  ) : (
    <div className="cover_img_cont">
      <img src={cover} alt="images" className="cover_img" />
    </div>
  );
}

export default Landing;
