import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import assesment from "../../image/newImg/asses.png";
import { Row, Col, Button } from "react-bootstrap";
import "./Content.css";
import ContentMain from "./ContentMain";
import ExaminationCard from "./ExaminationCard";

import { assesment_Data, grading_Data } from "../../variable";
function Examination(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    console.log("asd");
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#0076BD" }}>
            <img
              className="admissions__image"
              src={assesment}
              style={{ marginRight: "1rem" }}
            />
            Assessment
          </div>
          <div>
            <div
              className="d-flex gap-2 mt-1"
              style={{ flexDirection: "column" }}
            >
              <div>
                {/* <div className="f-bold f-1-2 mb-2"></div> */}
                <div className="color-grey f-500 mb-2">
                  <h2>LKG & UKG</h2>
                  The school follows a system of continuous & comprehensive
                  evaluation throughout the year for the students of Pre Primary
                  wing. The grades are given on the basis of class performance,
                  assessments, personality traits and participation in various
                  activities etc.
                </div>
                <div className="color-grey f-500 mb-2 ">
                  <h2>Class I to V</h2>
                  In an academic year for class I to V Four Formative
                  Assessments (FA) are conducted. In each FA various skills &
                  personality traits are assessed through specified activities,
                  interactions & written tests.
                  <div
                    className="mandatory_table"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {assesment_Data.map((item, index) => (
                      <Row
                        className="mandatory_list"
                        style={{ width: "80%" }}
                        key={index}
                      >
                        <Col className="mandatory_col" lg={4}>
                          {item.title}
                        </Col>
                        <Col className="mandatory_col-last" lg={4}>
                          {item.name}
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
                <div className="color-grey f-500  mb-2">
                  Participation and Performance in class activities, homework,
                  project work, & other subject enrichment activities is also
                  given weightage in the evaluation.
                </div>
              </div>
              <div>
                {/* <div className="f-bold f-1-2 mb-2"></div> */}
                <div className="color-grey f-500 mb-2">
                  <h5>
                    Assessment of scholastic area is reported on 5 point grading
                    scale.
                  </h5>
                </div>
                <div className="color-grey f-500 mb-2 ">
                  <h2>GRADING SYSTEM</h2>

                  <div
                    className="mandatory_table"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {grading_Data.map((item, index) => (
                      <Row
                        className="mandatory_list"
                        style={{ width: "80%" }}
                        key={index}
                      >
                        <Col className="mandatory_col" lg={4}>
                          {item.title}
                        </Col>
                        <Col className="mandatory_col" lg={4}>
                          {item.name}
                        </Col>
                        <Col className="mandatory_col-last" lg={4}>
                          {item.description}
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
                <div className="color-grey f-500  mb-2">
                  Assessment of Co Scholastic area such as life skills, music,
                  dance, and physical & health education is made on a 3-point
                  grading scale. (A+, A & B)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Examination;
